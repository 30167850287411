<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">封面：</div>
          <div class="item-content-div">
            <div class="img-div" v-show="!isShowAddImg">
              <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg" />
              <img ref="img" src="" alt="">
            </div>
            <div v-show="isShowAddImg">
              <label class="upload-img-label" @change="onAddImg($event)">
                <img class="cursor-el" src="../../assets/picture.png" alt="" />
                <input class="cursor-el" type="file" />
              </label>
            </div>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">标题：</div>
          <div class="item-content-div">
            <validation-provider rules="required|max:60" v-slot="{ errors }">
              <label><input class="video-name-input" name="title" type="text" v-model="pinInfo.name" maxlength="60" placeholder="请输入标题"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">价格：</div>
          <div class="item-content-div">
            <validation-provider rules="required|amount" v-slot="{ errors }">
              <label><input class="price-input" name="price" type="text" v-model="pinInfo.price" placeholder="请输入价格"> 元</label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">数量：</div>
          <div class="item-content-div">
            <validation-provider rules="required|num" v-slot="{ errors }">
              <label><input name="num" type="text" v-model="pinInfo.num" placeholder="请输入数量"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { postTopicAdd, postTopicEdit } from "@/common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    treasureInfo: {
      type: Object,
      default: null
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      pinInfo: {
        name: "",
        num: 0,
        price: 0
      },
      isShowAddImg: true,
      coverImg: null,
      tipMsg: ""
    }
  },
  created() {
    if(this.treasureInfo != null){
      this.pinInfo.name = this.treasureInfo.name;
      this.pinInfo.num = this.treasureInfo.num;
      this.pinInfo.price = this.treasureInfo.price / 100;
      if(typeof(this.treasureInfo.cover) === "string" && this.treasureInfo.cover.length > 0){
        this.$nextTick(() => {
          this.$refs.img.src = `${this.$imgBaseURL}/${this.treasureInfo.cover}`;
          this.isShowAddImg = false;
        })
      }
    }
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onDelImg() {
      this.coverImg = null;
      this.$refs.img.src = "";
      this.isShowAddImg = true;
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.coverImg = img;
        that.isShowAddImg = false;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onSave() {
      if(this.coverImg == null && (typeof(this.treasureInfo.cover) !== "string" || this.treasureInfo.cover.length < 1)){
        this.setTipsMsg("请选封面")
        return;
      }

      let formData = new FormData();
      formData.append("name", this.pinInfo.name);
      formData.append("num", this.pinInfo.num);
      formData.append("price", `${this.pinInfo.price * 100}`);
      formData.append("file", this.coverImg);
      if(this.treasureInfo != null){
        formData.append("id", this.treasureInfo.id);
      }

      this.$refs.form.validate().then(res => {
        if(res){
          if(this.treasureInfo != null){
            this.editTopic(formData);
          }else{
            this.addTopic(formData);
          }
        }
      });
    },
    addTopic(data) {
      postTopicAdd(data)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    editTopic(data) {
      postTopicEdit(data)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .item-name-div {
        width: 70px;
        text-align: right;
      }
      .item-content-div {
        flex: 1;
        display: inline-flex;
        .img-div {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            align-items: center;
          }
          .del-img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        input[type="text"] {
          width: 108px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        .video-name-input {
          width: 218px;
        }
        .price-input {
          width: 198px;
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 10px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
      }
    }
  }
}
</style>